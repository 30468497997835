import { render, staticRenderFns } from "./sortable_list.vue?vue&type=template&id=0e95eb0a&lang=pug"
import script from "./sortable_list.vue?vue&type=script&lang=js"
export * from "./sortable_list.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports