import { render, staticRenderFns } from "./modal.vue?vue&type=template&id=2d3f81e4&lang=pug"
import script from "./modal.vue?vue&type=script&lang=js"
export * from "./modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports